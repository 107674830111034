html, body {
  height: 100%;
}
.successMessage {
  position: fixed;
  margin-top: 4em;
  margin-right: 1em;
  margin-left: 70%;
  border-radius: 25px;
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(219, 255, 221)
    );
  box-shadow: 0px 1px 1px 1px rgb(44, 63, 48);
  color: green;
  font-size: 20px;
  border-style: solid;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  animation-duration: 0.2s;
  animation-name: slidein;
  z-index: 9999;
}

@keyframes slidein {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 70%;
  }
}

.searchIcon {
  position: absolute;
}

.failureMessage {
  position: fixed;
  margin-top: 4em;
  margin-right: 0em;
  margin-left: 70%;
  border-radius: 25px;
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(248, 215, 215),
      rgb(252, 134, 140)
    );
  box-shadow: 0px 1px 1px 1px rgb(63, 40, 40);
  color: red;
  font-size: 20px;
  border-style: solid;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  width: auto;
  animation-duration: 0.2s;
  animation-name: slidein;
  z-index: 9999;
}

.repeatedMessage {
  color: rgb(109, 107, 4);
  background: lightgrey;
  font-size: 20px;
  border-style: solid;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}
.deleteMessage {
  position: float;
  bottom: 0;
  right: 0;
  border-radius: 25px;
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(248, 215, 215),
      rgb(252, 134, 140)
    );
  box-shadow: 0px 1px 1px 1px rgb(63, 40, 40);
  color: red;
  font-size: 20px;
  border-style: solid;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  width: auto;
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  transition: all ease-in-out 0.5s;
  z-index: 2;
  transition: all 0.5s;
  padding: 50px 15px;
  background: #fa5959;
  overflow-y: auto;
}
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu {
  padding-top: 30px;
}

.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
}

.nav-menu a {
  display: flex;
  align-items: center;
  color: #fdfdff;
  padding: 12px 15px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-size: 20px;
}

.nav-menu a:hover {
  text-decoration: none;
  color: rgb(85, 82, 82);
}

.nav-menu .active {
  text-decoration: none;
  color: rgb(85, 82, 82);
}

.categoriaPeque {
  position: relative;
  background-color: rgb(255, 255, 255);
  list-style: none;
  height: 50px;
  margin: 2px 4px 4px 0px;
  padding: 0 10px 0 10px;
  font-size: 12px;
  text-align: center;
  box-shadow: 0px 10px 10px -6px rgb(207, 206, 206);
  border-radius: 1em;
}

.categoriaPeque li {
  padding-top: 1.7em;
}

.categoriaPeque a {
  color: #070707;
  text-shadow: 0px 10px 10px -6px rgb(207, 206, 206); 
}

section {
  padding: 7em;
  position: relative;
}

/*----------------- BARRA DE NAVEGACIÓN --------------*/
#navbar {
  height: 5em;
  width: 100%;
  align-items: center;
  bottom: 0;
  position: fixed;
  justify-content: space-around;
  background-color: #f7f5f5;
  z-index: 9999;
}

.botonCerrar {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: 0;
  z-index: 9997;
}

.botonTrash {
  background: none;
  border: 0;
}

.botonCerrar i {
  font-size: 30px;
  color: #fa5959;
}

.logOutPeque {
  position: fixed;
  z-index: 9997;
}

.opcionMenu {  
  font-size: 1.5rem;
  font-style: #fdfbfb;
  padding-bottom: 1rem;
}

.opcionMenu a {
  color:rgb(180, 179, 179)
}

.logo {
  top: 0;
  right: 0;
  position: fixed;
  z-index: 9996;
}

.logo img {
  width: 10em; 
  height: 10em;
  padding: 1em;
}

@media(max-width: 1324px) {
  #navbar {
    display: none;
  }
  .logo img {
    width: 5em; 
    height: 5em;
    padding: 1em;
  }
  .tituloPagina {
    margin-top: 22px;
    font-size: 25px;
  }
  section {    
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .menu-boton {
    top: 0;
    position: fixed;
    background: none;
    border: 0;
    z-index: 9999;
    padding-left: 1rem;
    padding-top: 0.5em;  
  }
  
  .menu-boton i {
    font-size: 30px;
  }
}

@media(max-width: 334px){
  .logo img {
    width: 5em; 
    height: 5em;
    padding: 1em;
  }
  .tituloPagina {
    margin-top: 22px;
    font-size: 25px;
  }
}

@media(min-width: 1326px) {
  .menu-boton {
    display: none;
  }
}

@media(min-width: 100px) {
  .nav-menuPeque {
    left: 0;
    display: flex;
    flex-flow: row;
    position: absolute;
    margin-top: 3.4rem;
    width: 99%;
    overflow-x: auto;
    z-index: 9994; 
    align-items: stretch;   
  }
  .contenido button {
    font-size: 10px;
    padding: 0 0 1 0;
  }
  #header {
    display: none;
  }
  .botonProdInventario {
    display: none;
  }
  .botonProdInventarioPeque {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-color:rgb(211, 208, 208);
    color: rgb(165, 161, 161);
    padding: 2px 7px;
    margin: 5px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    border-radius: 5px;
  }
  .productoInventario {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-radius: 25px;
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);    
    width: 100px;
    height: auto;
    font-size: 11px;
  }
  .producto {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-radius: 25px;
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);    
    width: 100px;
    height: auto;
    font-size: 11px;
  }
  .descripcion {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70px;
  }
  .productoInventario img {
    width: 90px;
    height: 60px;
    padding-right: 1rem;
  }

  .productoInventario button {
    padding: 0 0 0 0;
  }

  .productoInventario h4 {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50px;
  }
  .producto img {
    width: 90px;
    height: 60px;
    padding-right: 1rem;
  }

  .producto h4 {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50px;
  }
  .productosInventario {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
  .productosVenta {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
}

@media(min-width: 320px) {
  .contenido button {
    font-size: 12px;
  }
  #header {
    display: none;
  }
  .botonProdInventario {
    display: none;
  }
  .botonProdInventarioPeque {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-color:rgb(211, 208, 208);
    color: rgb(165, 161, 161);
    padding: 2px 7px;
    margin: 5px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    border-radius: 5px;
  }
  .productoInventario {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-radius: 25px;
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    margin: 0em 0em 0em 0em;
    padding: 0em 0 0 0;
    width: 110px;
    height: auto;
    font-size: 12px;
  }
  .productoInventario img {
    width: 90px;
    height: 60px;
    padding-right: 1rem;
  }

  .productoInventario button {
    padding: 0 0 0 0;
  }

  .productoInventario h4 {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70px;
  }
  .producto {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-radius: 25px;
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    margin: 0em 0em 0em 0em;
    padding: 0em 0 0 0;
    width: 110px;
    height: auto;
    font-size: 12px;
  }
  .producto img {
    width: 90px;
    height: 60px;
    padding-right: 1rem;
  }

  .producto h4 {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70px;
  }
  .productosInventario {
    display: grid;
    grid-template-columns: repeat(3, 0fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
  .productosVenta {
    display: grid;
    grid-template-columns: repeat(3, 0fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
}

@media(min-width: 460px) {
  .contenido button {
    font-size: 12px;
  }
  #header {
    display: none;
  }
  .botonProdInventario {
    display: none;
  }
  .botonProdInventarioPeque {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-color:rgb(211, 208, 208);
    color: rgb(165, 161, 161);
    padding: 2px 7px;
    margin: 5px;
    text-align: center;
    text-decoration: none;
    font-size: 12px;
    border-radius: 5px;
  }
  .contenedorInventario {
    padding-left: 0.1rem;
  }
  .contenedorVenta {
    padding-left: 0.1rem;
  }
  .productoInventario {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-radius: 25px;
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    margin: 0em 0em 0em 0em;
    padding: 0em 0 0 0;
    width: 130px;
    height: auto;
    font-size: 12px;
  }
  .producto {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-radius: 25px;
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    margin: 0em 0em 0em 0em;
    padding: 0em 0 0 0;
    width: 130px;
    height: auto;
    font-size: 12px;
  }
  .descripcion {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
  .productoInventario img {
    width: 90px;
    height: 60px;
    padding-left: 1rem;
  }

  .productoInventario button {
    padding: 0 0 0 0;
  }

  .productoInventario h4 {
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
  .producto img {
    width: 90px;
    height: 60px;
    padding-left: 1rem;
  }

  .producto h4 {
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
  .productosInventario {
    display: grid;
    grid-template-columns: repeat(3, 0fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
  .productosVenta {
    display: grid;
    grid-template-columns: repeat(3, 0fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
}

@media(min-width: 602px) {
  .productoInventario {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
  .producto {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
  .productosInventario {
    display: grid;
    grid-template-columns: repeat(4, 0fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
  .productosVenta {
    display: grid;
    grid-template-columns: repeat(4, 0fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  } 
}

@media(min-width: 720px) {  
  .productosInventario {
    display: grid;
    grid-template-columns: repeat(5, 0fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
  .productosVenta {
    display: grid;
    grid-template-columns: repeat(5, 0fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
}

@media(min-width: 1080px) {
  .containerCarritoVenta {
    display: none;
  }
}

@media(max-width: 1079px) {
  .ventaPrevia {
    display: none;
  }
  .containerCarritoVenta {
    position: fixed;
    margin-top: 8rem;
    margin-right: 10px;
    right: 0;
    width: 50px;
    z-index: 9995;
  }
  .botonCarritoVenta {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-color:rgb(243, 126, 126);
    color: rgb(243, 126, 126);
    padding: 2px 7px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    border-radius: 5px;
  }
  .conteoVenta {
    color:#fdfbfb;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #fa5959;
    border: 0;
    border-radius: 10rem;
    font-size: 10px;
  }
}

@media(min-width: 888px) {
  #header {
    display: flex;
  }
  #headerPeque {
    display: none;
  }
  section {    
    padding-left: 1em;
    padding-right: 1em;
  }
  .contenedorInventario {
    padding-left: 13rem;
  }
  .contenedorVenta {
    padding-left: 12rem;
  }
  .productosInventario {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
  .productosVenta {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
  .productoInventario {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-radius: 25px;
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    margin: 1em 0em 0em 0em;
    padding: 1em;
    width: 180px;
    height: auto;
    font-size: 16px;
  }
  .producto {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-radius: 25px;
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    margin: 1em 0em 0em 0em;
    padding: 1em;
    width: 180px;
    height: auto;
    font-size: 16px;
  }
  .descripcion {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
  }
  .productoInventario img {
    width: 140px;
    height: 100px;
    padding-left: 0.5rem;
  }

  .productoInventario button {
    padding: 0 0 0 0;
  }
  .productoInventario h4 {
    font-size: 25px;
    padding-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
  }
  .producto img {
    width: 140px;
    height: 100px;
    padding-left: 0.5rem;
  }

  .producto h4 {
    font-size: 25px;
    padding-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
  }
  .contenido h3 {
    font-size: 35px;
  }  
}

@media(min-width: 1020px) {
  section {    
    padding-left: 3em;
    padding-right: 2em;
  }    
  .productosInventario {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
  .productosVenta {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
}

@media(min-width: 1200px) {
  section {    
    padding-left: 3em;
    padding-right: 3em;
  }
  .productosInventario {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
  .productosVenta {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
}

@media (min-width: 1400px) {  
  .productosVenta {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }  
}

@media (min-width: 1411px) {  
  .productosInventario {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }  
}

@media(min-width: 1620px) {
  .productosVenta {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
}

@media(min-width: 1658px) {
  .productosInventario {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }  
}

@media(min-width: 1816px) {
  .productosVenta {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    width: 500px;
    animation-duration: 0.6s;
    animation-name: slideinProdVenta;
  }
}
/*----------------- END BARRA DE NAVEGACIÓN ----------*/
/*----------------- DASHBOARD ------------------------*/
.tituloPagina {
  padding: 3rem;
}


@media (min-width: 540px) {
  .sectionDashBoard {
    padding: 1px;
    padding-bottom: 4rem;
    margin-left: 2%;
    overflow: hidden;
    align-items: center;
  }
  .dashboardHorizontal {
    display: grid;
    grid-template-columns: repeat(1, 0fr);
    grid-gap: 0.5rem;
    grid-auto-rows: minmax(auto, auto);
    width: auto;
    padding-bottom: 2em; 
  }
  .dashboardCard {
    width: 500px;
    height: 250px;
    border-radius: 25px;
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(245, 245, 245)
      );
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    padding: 1em 1em 1em 1em;
    margin: 1rem 1rem 1rem 1rem;
    animation-duration: 0.3s;
    animation-name: slideinCardDashBoard;
    font-size: 12px;
  }
}

@media (min-width: 610px) {
  .sectionDashBoard {
    padding: 1px;
    margin-left: 2%;
    overflow: hidden;
    align-items: center;
  }
  .dashboardHorizontal {
    display: grid;
    grid-template-columns: repeat(1, 0fr);
    grid-gap: 0.5rem;
    grid-auto-rows: minmax(auto, auto);
    width: auto;
    padding-bottom: 2em; 
  }
  .dashboardCard {
    width: 550px;
    height: 270px;
    border-radius: 25px;
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(245, 245, 245)
      );
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    padding: 1em 1em 1em 1em;
    margin: 1rem 1rem 1rem 1rem;
    animation-duration: 0.3s;
    animation-name: slideinCardDashBoard;
    font-size: 12px;
  }
}

@media (min-width: 830px) {
  .sectionDashBoard {
    padding: 1px 13% 0 7%;
    margin-left: 2%;
    overflow: hidden;
    align-content: center;
  }
  .dashboardHorizontal {
    display: grid;
    grid-template-columns: repeat(1, 0fr);
    grid-gap: 0.5rem;
    grid-auto-rows: minmax(auto, auto);
    width: auto;
    padding-bottom: 2em; 
  }
  .dashboardCard {
    width: 650px;
    height: 300px;
    border-radius: 25px;
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(245, 245, 245)
      );
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    padding: 1em 1em 1em 1em;
    margin: 1rem 1rem 1rem 1rem;
    animation-duration: 0.3s;
    animation-name: slideinCardDashBoard;
  }
}

@media (min-width: 1110px) {
  .sectionDashBoard {
    padding: 1px;
    margin-left: 2%;
    overflow: hidden;
    align-items: center;
  }
  .dashboardHorizontal {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    grid-gap: 0.5rem;
    grid-auto-rows: minmax(auto, auto);
    width: auto;
    padding-bottom: 2em; 
  }
  .dashboardCard {
    width: 500px;
    height: 270px;
    border-radius: 25px;
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(245, 245, 245)
      );
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    padding: 1em 1em 1em 1em;
    margin: 1rem 1rem 1rem 1rem;
    animation-duration: 0.3s;
    animation-name: slideinCardDashBoard;
    font-size: 12px;
  }
}

@media (min-width: 1220px) {
  .sectionDashBoard {
    padding: 1px;
    margin-left: 2%;
    overflow: hidden;
    align-items: center;
  }
  .dashboardHorizontal {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    grid-gap: 0.5rem;
    grid-auto-rows: minmax(auto, auto);
    width: auto;
    padding-bottom: 2em; 
  }
  .dashboardCard {
    width: 550px;
    height: 300px;
    border-radius: 25px;
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(245, 245, 245)
      );
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    padding: 1em 1em 1em 1em;
    margin: 1rem 1rem 1rem 1rem;
    animation-duration: 0.3s;
    animation-name: slideinCardDashBoard;
    font-size: 12px;
  }
}

@media (min-width: 1420px) {
  .sectionDashBoard {
    padding: 1px;
    margin-left: 2%;
    overflow: hidden;
    align-items: center;
  }
  .dashboardHorizontal {
    display: grid;
    grid-template-columns: repeat(2, 0fr);
    grid-gap: 0.5rem;
    grid-auto-rows: minmax(auto, auto);
    width: auto;
    padding-bottom: 2em; 
  }
  .dashboardCard {
    width: 650px;
    height: 300px;
    border-radius: 25px;
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(245, 245, 245)
      );
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    padding: 1em 1em 1em 1em;
    margin: 1rem 1rem 1rem 1rem;
    animation-duration: 0.3s;
    animation-name: slideinCardDashBoard;
  }
}

@media (min-width: 1640px) {
  .sectionDashBoard {
    padding: 1px 8rem;
    margin-left: 2%;
    overflow: hidden;
    align-items: center;
  }
}

@media(min-width: 385px){
  .busqueda {
    position: fixed;
    margin-left: 4rem;
    padding-top: 20px;
    z-index: 9995;
    font-size: 15px;
  }

  .busqueda i {
    padding-left: 0.5rem;
  }
}


@media(max-width: 384px){
  .busqueda {
    position: fixed;
    margin-left: 4rem;
    padding-top: 23px;
    z-index: 9995;
    font-size: 10px;
  }

  .busqueda i {
    padding-left: 0.5rem;    
  }
}

@media(max-width: 885px){  

  .divTabla {
    display: none;
  }

  .listaVistaPeque * {
    list-style: none;
    font-size: 14px;
  }

  .listaVistaPeque {
    text-align: center;
    align-content: center;
  }

  .listaVistaPeque ul {
    padding-left: 0;
  }
  .listaVistaPeque li {
    cursor: pointer;
    background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(240, 232, 233)
    );
    margin: 1em 1em 1em 1em;
    padding: 1em 1em 1em 1em;
    border-radius: 5px;
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
  }
}

@media(min-width: 886px){
  .listaVistaPeque {
    display: none;    
  }

  .busqueda {
    position: fixed;
    margin-left: 250px;
    /* padding-top: 1.5em; */
    z-index: 9995;
  }
}

@media(min-width: 540px){
  .dashboardVertical {
    visibility: hidden;
    width: 1px;
    height: 1px;
  }
}

@media(max-width: 549px){
  .botonPrincipalSuperior {
    display: none;
  }

  .botonPrincipalSuperiorPeque {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-color:rgb(243, 126, 126);
    color: rgb(243, 126, 126);
    padding: 1px 7px;
    /* margin-top: 2px;
    margin-left: 15rem; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    border-radius: 5px;
    position: absolute;
    margin-top: 8rem;
    right: 0;
  }

  .botonSuperiorExportar {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-color:rgb(243, 126, 126);
    color: rgb(243, 126, 126);
    padding: 1px 7px;
    /* margin-top: 2px;
    margin-left: 15rem; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    border-radius: 5px;
    position: absolute;
    margin-top: 8.3rem;
    margin-right: 2rem;
    right: 0;
  }

  .botonSuperiorCerrar {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-color:rgb(243, 126, 126);
    color: rgb(243, 126, 126);
    padding: 1px 7px;
    /* margin-top: 2px;
    margin-left: 15rem; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    border-radius: 5px;
    position: absolute;
    margin-top: 8.3rem;
    right: 0;
  }
  /* .botonPrincipalSuperior {
    margin-top: 50px;
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-color:rgb(243, 126, 126);
    color: rgb(243, 126, 126);
    padding: 2px 11px;
    width: 10rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
  } */

  .opcionesVistaSuperior {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 1.5rem;
    padding-top: 20px;
    align-content: center;
    margin-left: 12%;
    z-index: 9995;
  }
}

@media(max-width: 385px){
  .botonPrincipalSuperiorPeque {
    right: 0;
  }
}

@media(min-width: 520px){
  .botonGeneral {
    font-size: 16px;
    padding: 5px 11px;
    margin: 2px;
  }

  .formulario {
    position: relative;
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    border-radius: 25px;
    /* box-shadow: 0px 1px 1px 1px rgb(243, 126, 126); */
    /* margin: 0rem 0.5rem 0rem 50rem; */
    overflow-y: auto;
    height: 400px;
    padding: 2em;  
    margin-bottom: 1rem;
    text-align: center;

  }

  .formInputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
    grid-auto-rows: minmax(auto, auto);
    width: auto;
    padding-top: 0.5em;
    text-align: center;
  }
}
@media(max-width: 519px){
  .botonPrincipalSuperior{
    font-size: 12px;
  }
  .botonGeneral {
    font-size: 10px;
    padding: 5px 8px;
    margin-left: 10px;
  }
  .contenido h3 {
    font-size: 23px;
    padding-left: 10px;    
  }
  .formulario {
    position: relative;
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    border-radius: 25px;
    /* box-shadow: 0px 1px 1px 1px rgb(243, 126, 126); */
    /* margin: 0rem 0.5rem 0rem 50rem; */
    overflow-y: auto;
    height: 400px;
    padding: 1em;
    padding-top: 40px;
    margin-top: 1rem; 
    margin-bottom: 1rem;
    text-align: center;
    font-size: 15px;
  }
  .formulario h2 {
    font-size: 20px;
  }
  .formInputs {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1em;
    grid-auto-rows: minmax(auto, auto);
    width: auto;
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    text-align: justify;
  }
}

@media(min-width: 450px){
  .botonPrincipalSuperior {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-color:rgb(243, 126, 126);
    color: rgb(243, 126, 126);
    padding: 2px 11px;
    margin-left: 5px;
    margin-top: 8.3rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
  }

  .botonPrincipalSuperiorDoble {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-color:rgb(243, 126, 126);
    color: rgb(243, 126, 126);
    padding: 2px 11px;
    margin-top: 8.3rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
  }
  
  .opcionesVistaSuperior {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 1.5rem;
    padding-top: 20px;
  }

  .opcionesVistaSuperiorDoble {
    position: alsolute;
    right: 0;
    top: 0;
    margin-right: 1.5rem;
    padding-top: 20px;
  }
}

@media(max-width: 539px){
  .dashboardHorizontal {
    visibility: hidden;
    width: 1px;
    height: 1px;
  }
  .dashboardVertical {
    display: grid;
    grid-template-columns: repeat(1, 0fr);
    grid-gap: 1rem;
    grid-auto-rows: minmax(auto, auto);
    width: auto;
    padding-bottom: 0;
    justify-content: center;
  }
  .dashboardCardVertical {
    width: 250px;
    min-height: 500px;
    max-height: 2000px;
    border-radius: 25px;
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(245, 245, 245)
      );
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    padding: 1em 1em 1em 1em;
    animation-duration: 0.3s;
    animation-name: slideinCardDashBoard;
    font-size: 11px;
  }
}

@media(min-width: 450px) {
  .botonPrincipalSuperiorPeque {
    display: none;
  }
  .botonSuperiorCerrar {
    display: none;
  }
  .botonSuperiorExportar {
    display: none;
  }
}

@media(min-width: 271) {
  .botonPrincipalSuperiorPeque {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-color:rgb(243, 126, 126);
    color: rgb(243, 126, 126);
    padding: 1px 7px;
    /* margin-top: 2px;
    margin-left: 10rem; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    border-radius: 5px;
    position: absolute;
    right: 0;
  }
}

@media(max-width: 320px){
  .busqueda {
    margin-left: 3.5rem;
    font-size: 9px;
  }

  .botonPrincipalSuperiorPeque {
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    border-color:rgb(243, 126, 126);
    color: rgb(243, 126, 126);
    padding: 1px 7px;
    /* margin-top: 57px;
    margin-left: 3.7rem; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 5px;
    position: absolute;
    right: 0;
  }

  .opcionesVistaSuperior {
    position: absolute;
    /* margin-left: 7.5rem; */
    right: 0;
    margin-right: 1.5rem;
    z-index: 9995;
  }
}
@media(max-width: 270px) {
  .tituloPagina {
    font-size: 30px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .dashboardVertical {
    display: grid;
    grid-template-columns: repeat(1, 0fr);
    grid-gap: 3rem;
    grid-auto-rows: minmax(auto, auto);
    width: auto;
    margin-bottom: 2rem;
    justify-content: center;
    font-size: 12px;
  }
  .dashboardVertical h3 {
    font-size: 20px;
  }
  .dashboardCardVertical {
    width: 220px;
    height: 28rem;
    border-radius: 25px;
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(245, 245, 245)
      );
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    padding: 1em 1em 1em 1em;
    animation-duration: 0.3s;
    animation-name: slideinCardDashBoard;
  } 

}

@keyframes slideinCardDashBoard {
  from {
    margin-top: -90%;
    margin-left: -90%;
  }

  to {
    margin-top: 0%;
    margin-left: 0%;
  }
}

/* ----------- END DASHBOARD ---------------------*/
input[type='text'], input[type='password'], input[type='date'] {
  box-shadow: 0.5px 0.5px 0.5px 0.5px rgb(179, 56, 56);  
}



input:focus {
  border-color: rgb(238, 88, 88);
  box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075);
  inset: 0 0 8px rgba(95, 1, 1, 0.6);
  outline: 0 none;
}

select {
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(240, 232, 233),
      rgb(255, 255, 255)      
    );
    box-shadow: 0 0 1px rgba(95, 1, 1, 0.6);
    margin-left: 5px;
}

.divTabla {
  border-radius: 25px;
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(235, 235, 235)
    );
  box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
  animation-duration: 0.2s;
  animation-name: slideinTablas;
}

@keyframes slideinTablas {
  from {
    margin-left: -30%;
  }

  to {
    margin-left: 0%;
  }
}

.ventaPreviaContainer {
  position: fixed;
  z-index: 9995;
}

.containerVentaPrevEmergente {
  background: rgba(106, 112, 121, 0.85);
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  position: fixed;
  z-index: 9996;
  align-items: center;
  justify-content: center;
}

.containerReporte {
  position: relative;
}

.ventaPrevia {
  position: fixed;
  overflow-y: auto;
  right: 0;
  margin-top: 10em;
  margin-right: 10px;
  padding: 3em;
  border-radius: 25px;
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(240, 232, 233)
    );
  box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
  animation-duration: 0.5s;
  height: 60%;
  animation-name: slideinVentaPrevia;  
}




@keyframes slideinVentaPrevia {
  from {
    margin-left: 100em
  }

  to {
    margin-left: 90em;
  }
}

@keyframes slideinProdVenta {
  from {
    margin-top: -30%;
  }

  to {
    margin-top: 0%;
  }
}

form input {
  display: flex;
  justify-content: space-around;
}

input {
  width: 100%;
}

.accionesForm {
  text-align: center;
  padding-top: 2rem;
  position: relative;
  overflow-y: auto;
}

.ventaPrevPeque {
  position: relative;
    background-image: 
      linear-gradient(
        to bottom right,
        rgb(255, 255, 255),
        rgb(240, 232, 233)
      );
    box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
    border-radius: 25px;
    /* box-shadow: 0px 1px 1px 1px rgb(243, 126, 126); */
    /* margin: 0rem 0.5rem 0rem 50rem; */
    overflow-y: auto;
    height: 500px;
    padding: 1em;
    padding-top: 40px; 
    margin-bottom: 1rem;
    text-align: center;
    font-size: 13px;
}

.ventaPrevPeque h2, h3 {
  font-size: 25px;
}

.ventaPrevPeque .botonPeque {
  padding: 0 0 0 0;
  font-size: 10px;
  background: none;
  border: 0;
}

.ventaPrevPeque .nombreVenta {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50px;
}
.detalleDevolucion {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  grid-auto-rows: minmax(auto, auto);
  width: auto;
  height: auto;
  padding: 1rem 0;
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(240, 232, 233)
    );
  /* box-shadow: 0px 1px 1px 1px rgb(161, 148, 148); */
}

/* .detalleDevolucion div {
  /* display: grid */
/* } */

.loginPage, .emailVerification, .passwordReset {
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  position: fixed;
  z-index: 999;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.loginForm, .emailVerificationForm, .passResetForm {  
  /* align-items: center;
  justify-content: center;
  text-align: center; */
  padding: 2em 2em 2em 2em;
  width: auto;
  height: auto;
  border-radius: 25px;
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(240, 232, 233)
    );
  box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
}

.userLogin {
  margin-top: 3em;
  text-align: left;
}

.passwordLogin {
  text-align: left;
  margin-bottom: 2em;
}

.loginForm input {
  margin-left: 1em;
  margin-bottom: 2em;
}

.total {
  bottom: 0;
  position: relative
}

.producto {
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(240, 232, 233)
    );
  border-radius: 25px;
  box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
  margin-left: 1rem;
  margin-top: 1rem;
  padding: 1rem 1rem 1rem 1rem;
  cursor: pointer;
}

.productoInventario {
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(240, 232, 233)
    );
  border-radius: 25px;
  box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
  margin-left: 1rem;
  margin-top: 1rem;
  padding: 1rem 1rem 1rem 1rem;
}




/* .contenido {
  padding-top: 2em;
} */

/* .contenido {
  margin-top: 2em;
} */

/* .contenido h2 {
  margin-top: 2em;
  top: 0;
} */

#botonPaginator {
  position: float;
}

/* #headerPaginator {
  
} */

.navtab {
  padding-left: 4em;
  font-size: 1.5rem;
  font-style: #fdfbfb;
}

.navtab a {
  color:rgb(180, 179, 179)
}

.botonGeneral {
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(240, 232, 233)
    );
  border-color:rgb(211, 208, 208);
  color: rgb(165, 161, 161);  
  text-align: center;
  text-decoration: none;
  display: inline-block;  
  border-radius: 5px;
}

/* .botonGeneral::before,
.botonGeneral::after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  z-index: -1;
}

.botonGeneral::after {
  background-color: #292929;
}

.botonGeneral::before {
  background-image: 
    linear-gradient(
      to bottom right,
      #aeffb9 0%,
      #a091ff 100%
    );
  transition: transform 200ms;
}

.botonGeneral:hover::before,
.botonGeneral:focus::before {
  transform: scale(1.1)
} */

.botonPrincipal {
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(240, 232, 233)
    );
  border-color:rgb(243, 126, 126);
  color: rgb(243, 126, 126);
  padding: 5px 11px;
  margin: 2px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;  
}


.botonProdInventario{
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(240, 232, 233)
    );
  border-color:rgb(211, 208, 208);
  color: rgb(165, 161, 161);
  padding: 2px 7px;
  margin: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  border-radius: 5px;
}

.botonPeque{
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(240, 232, 233)
    );
  border-color:rgb(211, 208, 208);
  color: rgb(165, 161, 161);
  padding: 2px 5px;
  margin: 2px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 5px;
}

.ventanaEmergente {
  background: rgba(106, 112, 121, 0.85);
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  position: fixed;
  z-index: 9997;
  align-items: center;
  justify-content: center;
}

.ventanaEmergenteMenu {
  background: rgba(106, 112, 121, 0.85);
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  position: fixed;
  z-index: 99999;
  align-items: center;
  justify-content: center;
}

.confirmarVenta {
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(240, 232, 233)
    );
  box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
  border-radius: 25px;
  /* box-shadow: 0px 1px 1px 1px rgb(243, 126, 126); */
  /* margin: 0rem 0.5rem 0rem 50rem; */
  padding: 3em;
  margin-bottom: 10rem;
  text-align: center;
  z-index: 9990
  /* justify-content: center; */
}

.productosConfirmarVenta th, td{
  padding:0.5em
}


.confirmarAccion {
  position: relative;
  background-image: 
    linear-gradient(
      to bottom right,
      rgb(255, 255, 255),
      rgb(240, 232, 233)
    );
  box-shadow: 0px 1px 1px 1px rgb(161, 148, 148);
  border-radius: 25px;
  /* box-shadow: 0px 1px 1px 1px rgb(243, 126, 126); */
  /* margin: 0rem 0.5rem 0rem 50rem; */
  padding: 2em;
  margin-top: 8rem;
  margin-bottom: 10rem;
  text-align: center;
}

.nombreVenta {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70px;
}

.ventaPrevia table {
  font-size: 14px;
}

.ventaPrev .botonPeque {
  padding: 0 0 0 0;
  font-size: 10px;
  background: none;
  border: 0;
}

.containerCarritoVenta {
  position: fixed;
}
